<template>
  <div>
    <!-- select 2 demo -->
    <b-modal id="modal-report" centered ok-only size="sm" hide-footer="true" title="Referred users">
      <b-row v-for="data in reportObj" :key="data.id" style="margin: 5px 0px; align-items: center;">
        <b-avatar :src="data.avatar" size="46" class="mr-1" />
        <div>
          <h6>{{ data.name }}</h6>
          <h6>{{ data.role }}</h6>
        </div>
      </b-row>
    </b-modal>

    <b-card no-body class="mb-0 ">
      <div class="m-2">
        <b-row align-h="start" style="align-items: center ;">
          <b-col class="" cols="*" lg="*" md="12" sm="*">
            <b-form-group label="Search" invalid-feedback="Search field">
              <b-form-input id="name-input" ref="name" placeholder="Enter name or email to search" required
                v-model="searchQuery" />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table :items="filters" :fields="fields" striped>
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(actions)="data">
          <template>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="ml-1 btn-icon"
              v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Referred" size="16" @click="View(data.item)">
              <feather-icon size="16" icon="EyeIcon" />
            </b-button>
          </template>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BEmbed,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    //Multiselect,
    BEmbed,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    BAvatar,
    BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
        type: [String, null],
        default: null,
        },
        modalFilterOptions: {
        type: Array,
        required: true,
        }*/
  },
  computed: {
    filters: function() {
      return this.allItems.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.email.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data() {
    return {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic",
      plus: "",
      loadingReels: false,
      loadingPost: false,
      reportObj: [],
      fileProfile: "",
      logoloading: false,
      cover: "",
      coverProfile: "",
      coverLoading: false,
      moreloading: false,
      morefile: null,
      errors: {
        status: false,
      },
      statusOptions: ["active", "inactive"],
      index: null,
      fields: [
        "#",
        { label: "referral code", key: "referralCode" },
        { label: "Name", key: "name" },
        { label: "email", key: "email" },
        { label: "total Referrals", key: "totalReferrals" },
        "actions",
      ],
      filterStatus: "",
      items: [],
      allItems: [],
      request: false,
      myObj: {
        id: 0,
        profilePic: "",
        name: "",
        title: "",
        cover: "",
        details: "",
        date: "",
        time: "",
        postedOn: "",
        status: "active",
        moreImages: "",
      },
      searchQuery: "",
      isUpdating: false,
      moreImg: [],
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    isVideo(url) {
      return /\.(mp4)$/.test(url);
    },
    View(item) {
      console.log(item);
      if (item.totalReferrals == 0) {
        this.$bvToast.toast("No referred users.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        var axios = require("axios");
        var config = {
          method: "get",
          url:
            "https://api.geotalent.co/api/Users/LoadReferredList?userID=" +
            item.id,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };
        axios(config)
          .then((response) => {
            // console.log(response);
            this.reportObj = response.data.data;
            console.log(this.reportObj);
            this.$bvModal.show("modal-report");
            // if (this.reportObj.length > 0) {
            // }
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },

    LoadData() {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/Users/LoadReferrals",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.items = [];
          this.allItems = [];
          this.items = response.data.data;
          this.allItems = response.data.data;
          console.log(this.items);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

.scrolling-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper .moreImg {
  flex: 0 0 auto;
  position: relative;
}
</style>
